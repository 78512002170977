import React from 'react';
import map from 'lodash/map';
import { RADIO_DETAILS_ROUTE } from 'utils/routes';
import { GetRadioIndexQuery } from 'types';

import PageHeader from 'components/common/PageHeader';
import RadioListItem from './RadioListItem';

import styles from '../Releases/Releases.module.css';

interface IRadioProps {
  posts: GetRadioIndexQuery['posts'];
}

export const Radio: React.FC<IRadioProps> = ({ posts }) => {
  return (
    <>
      <PageHeader title="Rock Bottom Radio" />
      <section className={styles.releasesContainer}>
        <div className={styles.releases}>
          {map(
            posts.edges,
            ({ node: { title, slug, coverArt, publishDate } }) => (
              <RadioListItem
                key={slug}
                title={title}
                description={publishDate}
                to={RADIO_DETAILS_ROUTE(slug)}
                img={coverArt.fluid}
              />
            )
          )}
        </div>
      </section>
    </>
  );
};
