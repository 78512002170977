import React from 'react';
import { graphql } from 'gatsby';
import { RADIO_SEO } from 'utils/seo';

import Layout from 'components/layout';
import { Radio } from 'components/Radio';

import { GetRadioIndexQuery } from 'types';

interface IRadioIndexProps {
  data: GetRadioIndexQuery;
  location: Location;
}

const RadioPage: React.FC<IRadioIndexProps> = ({ data: { posts } }) => (
  <Layout
    title={RADIO_SEO.title}
    description={RADIO_SEO.description}
    pathname={RADIO_SEO.pathname}
  >
    <Radio posts={posts} />
  </Layout>
);

export const radioIndexQuery = graphql`
  query GetRadioIndex {
    posts: allContentfulRadio(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          publishDate(formatString: "MM.DD.YYYY")
          slug
          title
          coverArt {
            fluid(maxWidth: 400) {
              ...GatsbyContentfulFluid_noBase64
            }
          }
        }
      }
    }
  }
`;

export default RadioPage;
