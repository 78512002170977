import React from 'react';
import { Link } from 'gatsby';
import Img, { FluidObject } from 'gatsby-image';

import styles from './RadioListItem.module.css';

interface IRadioListItemProps {
  to: string;
  title: string;
  img: FluidObject;
  description: string;
}

const RadioListItem: React.FC<IRadioListItemProps> = ({
  to,
  title,
  img,
  description,
}) => (
  <article className={styles.radioItem}>
    <div className={styles.radioItemImgContainer}>
      <Link to={to} className={styles.radioItemImgLinkContainer}>
        <Img
          fluid={img}
          alt={`${title} cover art`}
          className={styles.radioItemImg}
          style={{ position: 'absolute' }}
        />
      </Link>
    </div>
    <div className={styles.radioItemBody}>
      <h3 className={styles.radioItemTitle}>
        <Link to={to} className={styles.radioItemTitleLink}>
          {title}
        </Link>
      </h3>
      <p className={styles.radioItemDescription}>{description}</p>
    </div>
  </article>
);

export default RadioListItem;
